import qs from 'qs';
import axios from "axios";
import { message } from "antd";
import redux from '@/redux/store'
import loginOut from '@/utils/loginOut'

export default {
  baseURL: (() => {   // 判断是在生产环境还是开发环境，如果是生产环境baseURL为/api
    if (process.env.NODE_ENV === "development") {
      return '/api'
    } else {
      // return "http://train.513online.top/api/v1"
      return 'http://prep.513.com/api/v1'
    }
  })(),
  ajaxs(getUrl, params, methods) {
    return new Promise((resolve, reject) => {
      let data = Object.assign({}, params);

      let axiosParams = {
        url: this.baseURL + getUrl,
        method: methods ? methods : "POST",
        timeout: 1000 * 60 * 5,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      }
      //添加参数
      if (methods === 'GET') {
        Object.defineProperty(axiosParams, 'params', {
          value: data,
          enumerable: true
        })
      } else {
        Object.defineProperty(axiosParams, 'data', {
          value: data,
          enumerable: true
        })
      }
      axios(axiosParams).then(res => {
        if (res.data.code === 200) {
          resolve(res.data);
        } else if (res.data.code === 401) {
          // 退出登录状态
          loginOut(1)
        } else {
          reject()
          message.error(res.data.message);
        }
      },
        err => {
          message.error("哎呀呀，后台系统出错啦！")
        }
      )
        .catch(err => {
          message.error("哎呀呀，前端系统出错啦！")
        });
    });
  },
  //文件上传
  uploadFile(getUrl, params) {
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append('files', params);
      let config = {
        url: this.baseURL + getUrl,
        data: param,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
      axios(config).then(
        res => {
          if (res.data.code === 200) {
            resolve(res.data);
          } else {
            message.error(res.data.message);
            reject(res.data);
          }
        },
        err => {
          reject(err);
        }
      )
        .catch(err => {
          throw new Error(err);
        });
    });
  },
};