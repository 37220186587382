import React from 'react'
import './video.css'
import '@/assets/font_icon/iconfont.css'
import { Layout, Progress, Menu, Tooltip } from 'antd';
import AJAX from '@/api/api'
import getUrlData from '@/utils/getUrlData'
import numberToCn from '@/utils/numberToCn'

const { Header, Sider, Content } = Layout
const { SubMenu } = Menu;
var player = null;
var Interval = null;
export default class video extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rainId:'',//课程ID
            trainChapterId:'',//章节ID
            data: {
                trainTotalVideoProgress: 0,
                trainVideoChapterVos: []
            },
            video: {},
            openKey: [],
            defaultSelectedKey: [],
            thenprogress: 0,
        }
    }

    componentDidMount() {
        this.setState({
            rainId:getUrlData('id'),
            trainChapterId:getUrlData('trainChapterId')
        },()=>{
            this.getData()
        })
    }
    
    //添加全局事件
    keyDown(e) {
        let getStatus = player.getStatus()
        //键盘左/右箭头控制快进/回退
        if (
            (e.code == 'ArrowRight' || e.code == 'ArrowLeft') &&
            (getStatus == 'playing' || getStatus == 'pause')
        ) {
            let sum =  player.getCurrentTime()
            if (e.key == 'ArrowRight') {
                sum += 5
            } else {
                sum -= 5
            }
            player.seek(sum)
            //键盘空格键控制播放与暂停
        } else if (e.code == 'Space') {
            if (getStatus == 'pause' || getStatus == 'ready') {
                player.play()
            } else if (player.getStatus() == 'playing') {
                player.pause()
            }
        }
    }
    //创建阿里播放器
    newAli(data) {
        player = new window.Aliplayer({
            "id": "player-con",
            "vid": data.videoId,
            "playauth": data.playAuth,
            "cover":data.coverUrl,
            "qualitySort": "asc",
            "format": "mp4",
            "mediaType": "video",
            "width": "100%",
            "height": "99%",
            "autoplay": true,
            "isLive": false,
            "rePlay": false,
            "playsinline": true,
            "preload": true,
            "controlBarVisibility": "hover",
            "useH5Prism": true,
            "definition":'FD,LD,SD,HD,OD,2K,4K',
            "defaultDefinition":'LD'
        }, function (player) {
            player.play();
        })
        //视频由暂停恢复为播放
        player.on('play', ()=> {
            this.timeStart()
        })

        //视频暂停
        player.on('pause', ()=> {
            this.stopTime()
        })

        //当前视频播放完毕·
        player.on('ended', ()=> {
            this.timeStart()
        })
    }

    //开始30秒上报一次
    timeStart(){
        this.stopTime()
        Interval = setInterval(() => {
            this.heartbeat()
        },30000);
    }

    //停止30秒上报一次
    stopTime(){
        clearInterval(Interval)
        Interval = null
        this.heartbeat()
    }

    //视频进度上报
    async heartbeat(){
        if(player){
            AJAX.live.getVideoHeartbeat({
                trainChapterId: parseInt(this.state.trainChapterId),
                videoId:this.state.video.videoId,
                reportDuration:player.getCurrentTime() || 0
            })
        }
    }

    async getData() {
        document.addEventListener('keydown', this.keyDown)
        await AJAX.live.getVideoList(this.state.rainId).then(res => {
            let openKeys = []
            let defaultSelectedKey = []
            let video = {
                maxName:res.data.trainName
            }
            for (let i = 0; i < res.data.trainVideoChapterVos.length; i++) {
                openKeys.push(i.toString())
                res.data.trainVideoChapterVos[[i]].childList.forEach((item, index) => {
                    if (item.trainChapterId == this.state.trainChapterId) {
                            video.videoId = item.trainChapterUserVideo.videoId
                            video.videoName = item.trainChapterName
                            video.chapter = `第${numberToCn(i+1)}章`
                            defaultSelectedKey = [`${i+1}.${index+1}`]
                            this.getAliJurisdiction(item)
                    }
                })
            }
            this.setState({
                data: res.data,
                openKeys: openKeys,
                defaultSelectedKey: defaultSelectedKey,
                video: video
            })
        })
    }

    async getAliJurisdiction(data){
        await AJAX.live.getVideoAuthority(data.trainChapterUserVideo.videoId).then(async Response => {
            this.newAli(Response.data)
        })
    }

    withdraw(){
        if(player.getStatus()=='pause'){
            player.pause()
        }
        window.history.back(-1)
    }

    //切换视频
    videoChange(data,index){
        //暂停一下，触发上报进度
        if(player.getStatus()=='pause'){
            player.pause()
        }
        //销毁ali播放器
        player.dispose();
        player = null;
        //创新新的阿里播放器
        this.getAliJurisdiction(data)
        let video = {
            videoId:data.trainChapterUserVideo.videoId,
            videoName:data.trainChapterName,
            chapter:`第${numberToCn(index+1)}章`,
        }
        let maxName = this.state.video.maxName
        this.setState({
            trainChapterId:data.trainChapterId,
            video:{...video,maxName:maxName}
        })
    }

    setView() {
        if (this.state.data.trainVideoChapterVos.length) {
            return <Menu mode="inline" style={{ width: 380 }} defaultOpenKeys={this.state.openKeys} defaultSelectedKeys={this.state.defaultSelectedKey}>
                {this.state.data.trainVideoChapterVos.map((item, index) => <SubMenu key={index} title={
                    <div className="chapter">
                        <label>{`第${numberToCn(index + 1)}章`}<span /></label>{item.trainChapterName}
                    </div>}>
                    {item.childList.map((item2, index2) =>
                        <Menu.Item key={`${index + 1}.${index2 + 1}`} onClick={()=>{this.videoChange(item2,index)}}>
                            <div className="chapter">
                                <label>{`${index + 1}.${index2 + 1}`}</label>
                                <div>
                                    <Tooltip placement="topLeft" mouseEnterDelay={1} title={item2.trainChapterName}>
                                        <p>{item2.trainChapterName}</p>
                                        <p><span className="iconfont iconplay" />{`${(item2.trainChapterUserVideo.videoDuration / 60).toString().split('.')[0]}分钟`}</p>
                                    </Tooltip>
                                </div>
                                <Progress type="circle" percent={item2.trainChapterUserVideo.videoProgress} className="thisProgress" />
                            </div>
                        </Menu.Item>)}
                </SubMenu>
                )}
            </Menu>
        }
    }

    render() {
        return (
            <div className="playerMain">
                <Layout>
                    <Header>
                        <label onClick={()=>this.withdraw()}><img src={require("@/assets/images/player/nav_icon_return.png")} />返回上一级</label>
                        <h1>{this.state.video.maxName}</h1>
                    </Header>
                    <Content>
                        <Layout className="site-layout">
                            <Content className="videoContent">
                                <Header>
                                    <p>{this.state.video.chapter} {this.state.video.videoName}</p>
                                    <p><img src={require("@/assets/images/player/icon_ train.png")} />实训介绍</p>
                                </Header>
                                <Content className="videoBox">
                                    <div className="prism-player" id="player-con"></div>
                                </Content>
                            </Content>
                            <Sider className="rightSelect">
                                <div className="myProgress">
                                    <p>实训课程总进度 <span>{`${this.state.data.trainTotalVideoProgress}%`}</span></p>
                                    <Progress percent={this.state.data.trainTotalVideoProgress} showInfo={false} status="active" ></Progress>
                                </div>
                                <p className="catalogueTitle">目录</p>
                                <div className="menuBox">
                                    {this.setView()}
                                </div>
                            </Sider>
                        </Layout>
                    </Content>
                </Layout>
            </div>
        )
    }
}