import React from 'react'
import './header.css'
import { Menu, message } from 'antd';
import { Link, withRouter } from 'react-router-dom'
import redux from '@/redux/store'
import loginOut from '../../../utils/loginOut';

class header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userImg: "",
            userName: "",
            menuList: [
                // {
                //     name: '首页',
                //     path: '/',
                // },
                // {
                //     name: '课程',
                //     path: '/course',
                // },
                {
                    name: '实训',
                    // path: '/practicalTraining',
                    path: '/',
                },
                // {
                //     name: '就业',
                //     path: '/job',
                // },
                // {
                //     name: '资讯',
                //     path: '/information',
                // },
            ],
            thisPath: ['/'],
        }
    }
    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }
    //绑定页面与导航栏的对应，使其浏览器返回与前进时，导航栏选择项不会停滞不变，以及进入子页面导航栏选择项缺失
    componentWillReceiveProps(nextProps) {
        let thisPath = nextProps.location.pathname.split('/')[1];
        let toPath = ['']

        this.state.menuList.forEach(item => {
            if (item.path.split('/')[1] == thisPath) {
                toPath = [item.path]
            }
        })

        this.setState({
            thisPath: toPath
        })
    }

    isLogin() {
        if (redux.getState().userInfo) {
            let userName = redux.getState().userInfo.name ? redux.getState().userInfo.name : redux.getState().userInfo.phone
            return <div className="user">
                <img className="portrait" src={require("../../../assets/images/public/user.png")}></img>
                <p>{userName}</p>
                <img className="userChange" src={require("../../../assets/images/public/nav_btn_triangle.png")}></img>
                <div className='userPopup'>
                    {/* <Link to='/setting'>我的设置</Link> */}
                    <Link to='' onClick={()=>loginOut(0)}>退出</Link>
                </div>
            </div>
        }else{
            return <div className="user" style={{width:'auto'}}>
                <Link to='/login' >登录</Link>/<Link to='/login/signUp' >注册</Link>
            </div>
        }
    }

    render() {
        return (
            <div className="header">
                <div className="content">
                    <img className="logo" src={require("../../../assets/images/public/nav_logo.png")} />
                    <Menu defaultSelectedKeys={['/']} selectedKeys={this.state.thisPath} className="navigationBar" mode="horizontal">
                        {this.state.menuList.map(item =>
                            <Menu.Item key={item.path}>
                                <Link to={{ pathname: item.path }}>{item.name}</Link>
                            </Menu.Item>
                        )}
                    </Menu>

                    <div className="search">
                        <input placeholder="输入课程/学校/老师"></input>
                        <img src={require("../../../assets/images/public/nav_search.png")}></img>
                    </div>
                    {this.isLogin()}
                </div>
            </div>
        )
    }
}

export default withRouter(header)