import React from 'react';
import LoadableComponent from '@/utils/LoadableComponent'
import HeaderBar from '@/components/public/headerView/headerView'
import FooterBar from '@/components/public/footerView/footerView'
import './pages.css'
import CN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

let Router = LoadableComponent(() => import('../Router'));
//三板块主页面
export default class ContentMain extends React.Component {
    render(){
        return(
            <div>
                <div className="mainHeader">
                    <HeaderBar />
                </div>
                <div className="mainContent">
                    <ConfigProvider locale={CN}>
                        <Router />
                    </ConfigProvider>
                </div>
                <div className="mainFooter">
                    <FooterBar />
                </div>
            </div>
        )
    }
}