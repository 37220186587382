
import AJAX from "@/api/api.js";
export default function(_this, form ,type){
	// 触发表单验证
	form.validateFields(["phone"]).then(values => {
		// 表单验证成功
		let phoneCodeTxt = _this.state.phoneCodeTxt;
		let defaultTxt = "获取验证码"
		if(phoneCodeTxt==defaultTxt){
			AJAX.login.getPhoneCode({phone:values.phone,type:type})
			phoneCodeTxt = 60
			let getPhoneCodeTime = setInterval(()=>{
				if(phoneCodeTxt<=0){
					_this.setState({
						phoneCodeTxt:defaultTxt
					})
					clearInterval(getPhoneCodeTime)
					return ;
				}
				phoneCodeTxt--
				_this.setState({
					phoneCodeTxt:phoneCodeTxt+"秒后重试"
				})
			},1000)
		}
	}).catch(errorInfo => {
		// 表单验证失败
	})
	
}