import React from "react"
import './login.css'
import SignIn from './module/signIn'
import SignUp from './module/signUp'
import getBackPassword from './module/getBackPassword'
import {Route,Switch} from 'react-router-dom';
//登录
export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="loginMain">
                <div className="loginBanner">
                    <div className="content">
                        <div className="head">
                            <img src={require("../../assets/images/login/nav_logo.png")}></img>
                            <ul>
                                <li>APP下载</li>
                                <li>会员中心</li>
                            </ul>
                        </div>
                        <div className="signModule">
                            <Switch>
                                <Route exact path="/login" component={SignIn}></Route>
                                <Route exact path="/login/signUp" component={SignUp}></Route>
                                <Route exact path="/login/getBackPassword" component={getBackPassword}></Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                <div className="official">
                    <div className="content">
                        <div>
                            <p>
                                <img src={require('../../assets/images/login/footer_icon_phone.png')}></img>
                                400-8888-513
                            </p>
                            <p>地址：深圳市南山区西丽同乐路1002号留仙文化园三栋3层</p>
                            <p>Copyright ©深圳市伍壹叁教育科技有限公司 版权所有 <a href="http://www.beian.miit.gov.cn/">粤ICP备19126219号-1</a></p>
                        </div>
                        <ul>
                            <li>
                                <img src={require('../../assets/images/public/qr_code_wechat.png')}></img>
                                <p>513官方微信</p>
                            </li>
                            <li>
                                <img src={require('../../assets/images/public/qr_code_app.png')}></img>
                                <p>513教育APP</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}