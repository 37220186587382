import React from 'react';
import './App.css';
import {Route,Switch} from 'react-router-dom';
import ContentMain from '@/routes/contentMain/contentMain';
import Login from '@/view/login/login'
import Player from '@/view/video/video'
function App() {
  return (
    <Switch>
      {/* 登录 */}
      <Route path="/login" component={Login} />
      {/* 播放视频页面 */}
      <Route exact path="/player" component={Player} />
      {/* 主页 */}
      <Route path="/" component={ContentMain} />
    </Switch>
  );
}

export default App;