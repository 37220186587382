import Ajax from "./request"

export default {
    login:{
        signIn(params){  // 登录
            return Ajax.ajaxs("/user/login", params);
        },
        signUp(params){   // 注册
            return Ajax.ajaxs("/user/register", params);
        },
        getBackPassword(params){   // 找回密码
            return Ajax.ajaxs("/user/reset/password", params);
        },
        getCodeImgUrl(){   // 图片验证码
            return Ajax.ajaxs("/verifyCode", {},'get');
        },
        getPhoneCode(params){   //短信验证码
            return Ajax.ajaxs("/sendMobileCode", params);
        },
        logOut(){               //退出登录
            return Ajax.ajaxs("/user/logout", {});
        }
    },
    training:{
        getRecommendedPracticeList(params){         // 根据类别id获取对应的推荐实训
            return Ajax.ajaxs("/train/recommends", params);
        },
        getpracticalTrainingHeaderDetail(params){   // 根据实训ID获取实训头部详情
            return Ajax.ajaxs("/train/detail/header/"+params,{},"get");
        },
        getpracticalTrainingBodyrDetail(params){   // 根据实训ID获取实训内容详情
            return Ajax.ajaxs("/train/detail/body/"+params,{},"get");
        },
        getMyTrainingList(params){                  // 获取我的实训
            return Ajax.ajaxs("/train/myTrains",params);
        },
        getMyWorksList(params){                     // 获取实训作业列表
            return Ajax.ajaxs("/train/works/"+params,{},"get");
        },
        getMyPlanList(params){                      // 获取实训章节
            return Ajax.ajaxs("/trainChapter/train/"+params,{},"get");
        },
        getMyResourcesList(params){                 // 获取资料列表
            return Ajax.ajaxs("/train/resources/"+params,{},"get");
        },
        upAssignment(params){                       // 上传作业
            return Ajax.ajaxs("/train/work/workAnswer/upload",params);
        },
        getExamineList(params){                     // 获取考核列表
            return Ajax.ajaxs("/train/examination/"+params,{},"get");
        },
        upExamineFile(params){                      // 上传考核文件
            return Ajax.ajaxs("/train/examination/examinationAnswer/upload",params);
        },
        getTrainingDetail(params){                  // 获取实训详情
            return Ajax.ajaxs("/trainChapter/train/progress/"+params,{},'get');
        },
        isTrue(params){                             // 验证是否被加入学习
            return Ajax.ajaxs("/train/userHasTrain",params);
        }
    },
    live:{
        getVideoList(params){                  // 获取实训详情
            return Ajax.ajaxs("/video/trainVideo/"+params,{},'get');
        },
        getVideoAuthority(params){                   //获取播放视频权限
            return Ajax.ajaxs("/video/auth/"+params,{},'get');
        },
        getVideoHeartbeat(params){                   //获取播放视频权限
            return Ajax.ajaxs("/video/report",params,'put');
        },
    },
    file:{
        upload(params){                             // 上传文件
            return Ajax.uploadFile("/file/upload",params);
        },
        download(url){                              // 下载文件
            return Ajax.ajaxs("/file/download",{fileUrl:url});
        },
    }
}