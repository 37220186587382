export default function(key){
    //key存在先通过search取值如果取不到就通过hash来取
    let after = window.location.hash.split("?")[1];

    if(after){
        var reg = new RegExp("(^|&)"+ key +"=([^&]*)(&|$)");
        var r = after.match(reg);
        if(r != null){
            return  decodeURIComponent(r[2]);
        }else{
            return null;
        }
    }
}